import { MessageFormProps } from '../@types';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useRef } from 'react';
import lawftyData from '../assets/questions/lawfty_messages.json';
import './transitions.css';

const MessageForm = ({
  onMessageSend,
  disabled,
  language
}: MessageFormProps) => {
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const listener = (e: any) => {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById('message-send-btn')?.click();
      }
    }

    document.addEventListener('keydown', listener)

    setTimeout(() => {
      document.querySelector('.MessageList')?.scroll({
        top: document.querySelector('.MessageList')?.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 500);

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, []);

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onMessageSend(inputRef.current?.value);
    // @ts-ignore
    inputRef.current!.value = '';
  };

  return (
    // TODO: Transition Styles
    <CSSTransition
      classNames="MessageForm"
      in={true}
      timeout={1200}
      appear={true}
      enter={true}
      exit={true}
    >
      <form
        className="basis-[70px] flex-shrink-0 rounded-t-none rounded-b flex bg-eee border-0 p-1.25 text-black text-base box-border border-t border-t-solid border-t-ccc"
        onSubmit={handleFormSubmit}
      >
        <div className="flex-grow flex-shrink-0 basis-1/2">
          <textarea
            type="text"
            className="pl-1 w-full h-full bg-white box-border text-black border border-solid border-ccc outline-none touch-none text-2xl"
            // @ts-ignore
            ref={inputRef}
            placeholder={lawftyData.TextInput[language]}
            disabled={disabled}
          />
        </div>
        <div className="flex-grow-0 flex-shrink-0 basis-1/5 pl-1.25">
          <button
            type="submit"
            id="message-send-btn"
            className="bg-send text-white font-bold text-2xl border-2 border-send-border w-full h-full border-box outline-none"
          >
            {lawftyData.SendMessage[language]}
          </button>
        </div>
      </form>
    </CSSTransition>
  );
};

export default MessageForm;
