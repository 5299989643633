import { onValue } from 'firebase/database';
import { ButtonProps } from '../@types';
import { dbHelper, getRef } from '../helpers/db_helper';

const Button = ({
  body,
  onClick,
  answerId,
  disabled,
  postId,
  secondSetQuestionId,
  brand
}: ButtonProps) => {
  const handleClick = () => {
    if (secondSetQuestionId) {
      const dbRef = getRef('chat/posts/' + postId + '/NoloResponses');
      onValue(dbRef, (snapshot) => {
        const noloResponses = snapshot.val() || {};
        noloResponses[secondSetQuestionId - 1] = answerId;
        dbHelper('chat/posts/' + postId, { NoloResponses: noloResponses });
      });
    }
    onClick(body);
  };
  return (
    <>
      <button
        onClick={handleClick}
        disabled={disabled}
        className={`rounded-2xl m-1.25 py-1.25 px-2.5 text-white font-bold inline-block border-none leading-normal ${
          disabled
            ? body === 'NO'
              ? 'bg-disabled-no-btn'
              : `${brand === 'd2c' ? 'bg-disabled-d2c' : 'bg-disabled'}`
            : body === 'NO'
            ? 'bg-enabled-no-btn'
            : `${brand === 'd2c' ? 'bg-enabled-d2c' : 'bg-enabled'}`
        }`}
      >
        {body}
      </button>
    </>
  );
};

export default Button;
