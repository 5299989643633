import { MessageProps } from '../@types';
import ButtonGroup from './ButtonGroup';
import { DropdownButton } from 'react-bootstrap';
import Email from './Email';
import { useState } from 'react';
import Name from './Name';
import SelectButton from './SelectButton';
import PhoneNumber from './PhoneNumber';
import ZipCode from './ZipCode';

const Message = ({
  author,
  body,
  me,
  buttons,
  options,
  onClick,
  disabled,
  secondSetQuestionId,
  language,
  postId,
  conversationId,
  input,
  brand
}: MessageProps) => {
  const [isDisabled, setDisabled] = useState(disabled || false);
  const [text, setText] = useState('Pick an option');

  const createDropdown = (options_list: string[]) => {
    return (
      options_list && (
        <DropdownButton
          className="max-h-7 rounded m-1.25 py-1.25 px-2.5 text-white font-bold block border-0 leading-6 bg-enabled"
          id="dropdown-basic-button"
          title={text}
          rootCloseEvent="click"
          disabled={isDisabled}
        >
          {options_list.map((variant: any) => {
            return (
              <SelectButton
                styles="bg-white text-black rounded-[1px] leading-6 block p-0.75"
                secondSetQuestionId={secondSetQuestionId}
                postId={postId}
                key={variant.answer_text}
                body={variant.answer_text}
                answerId={variant.answer}
                onClick={dropdownSelectButtonClick}
              />
            );
          })}
        </DropdownButton>
      )
    );
  };

  const createInput = (input: string) => {
    if (input) {
      return (
        <>
          {input === 'ZipCode' && (
            <ZipCode
              onClick={onClick}
              postId={postId}
              language={language}
              disabled={isDisabled}
            />
          )}
          {input === 'PhoneNumber' && (
            <PhoneNumber
              onClick={onClick}
              postId={postId}
              language={language}
              disabled={isDisabled}
            />
          )}
          {input === 'Email' && (
            <Email
              onClick={onClick}
              postId={postId}
              language={language}
              disabled={isDisabled}
            />
          )}
          {input === 'Name' && (
            <Name
              onClick={onClick}
              conversationId={conversationId}
              postId={postId}
              disabled={isDisabled}
              language={language}
            />
          )}
        </>
      );
    }
  };

  const dropdownSelectButtonClick = (variant: string) => {
    setText(variant);
    setDisabled(true);
    onClick(variant);
  };

  return (
    <div
      className={`leading-6 mt-1 ${
        me ? 'text-right pl-[20%]' : author ? 'pr-[20%]' : 'pr-0'
      }`}
    >
      {author && (
        <span
          className={`font-bold mr-1 ${
            me
              ? 'text-author-me'
              : `${brand === 'd2c' ? 'text-author-d2c' : 'text-author'}`
          }`}
        >
          {author}
        </span>
      )}
      <div
        className={`text-black leading-normal text-[13px] pt-[6px] pr-2.5 pb-[8px] pl-[12px] ${
          author
            ? me
              ? `${
                  brand === 'd2c' ? 'bg-msg-me-d2c' : 'bg-msg-me'
                } rounded-b-2xl rounded-tl-2xl rounded-tr-none`
              : `${
                  brand === 'd2c' ? 'bg-msg-bot-d2c' : 'bg-msg-bot'
                } rounded-b-2xl rounded-tr-2xl rounded-tl-none`
            : `${
                brand === 'd2c' ? 'bg-d2c' : 'bg-white'
              } text-center text-black p-0`
        }`}
        style={{whiteSpace: "pre-line"}}
      >
        {body}
        <div>{createInput(input)}</div>
        <div>
          {buttons && (
            <ButtonGroup
              postId={postId}
              buttonList={buttons}
              secondSetQuestionId={secondSetQuestionId}
              onClick={onClick}
              disabled={isDisabled}
              brand={brand}
            />
          )}
        </div>
        <div>{createDropdown(options)}</div>
      </div>
    </div>
  );
};

export default Message;
