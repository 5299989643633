import { Language } from '../@types';
import lawftyData from '../assets/questions/lawfty_messages.json';

const Disclaimer = ({ language }: Language) => {
  return (
    <>
      <div className="inline bg-black text-xxs outline-none w-full p-[2px]">
        <span className="flex basis-11/12 m-[1px] pl-[1%] text-white">
          {lawftyData.Disclaimer[language]}
        </span>
      </div>
    </>
  );
};

export default Disclaimer;
