import { equalTo, onValue, query, update } from 'firebase/database';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import shortId from 'shortid';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faLock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

import { MessageProps } from './@types';
import lawftyData from './assets/questions/lawfty_messages.json';
import secondSetQuestions from './assets/questions/lawyer_questions.json';
import secondSetNolo from './assets/questions/nolo_questions.json';
import Disclaimer from './components/Disclaimer';
import FirmLogo from './components/FirmLogo';
import MessageForm from './components/MessageForm';
import MessageList from './components/MessageList';
import Placeholder from './components/Placeholder';
import SecurityNotification from './components/SecurityNotification';
import { dbHelper, getRef } from './helpers/db_helper';
import {
  closeChat,
  formatConversation,
  initialParameters,
  getCurrentTimeInNY
} from './helpers/message_helper';

// @ts-ignore
library.add(faLock);
// @ts-ignore
library.add(faTimesCircle);
// @ts-ignore
library.add(faArrowRight);

const inputFieldsSequence = ['Name', 'ZipCode', 'Email'];
const searchQuery = queryString.parse(window.location.search);
// @ts-ignore
const brand: string = searchQuery.brand || '';
// @ts-ignore
const language: 'english' | 'spanish' = searchQuery.language || 'english';
// @ts-ignore
const campaignId: string = searchQuery.firm || '';
// @ts-ignore
const campaignType: 'Employment' | 'PersonalInjury' =
  searchQuery.type || 'PersonalInjury';
const agentName = 'Patricia';
const button_list = lawftyData.ButtonList[campaignType][language];

const initialMessages: any[] = [
  {
    me: false, //log: 'true',
    body: lawftyData.Connecting[language]
  },
  {
    me: false,
    // log: 'true',
    body: lawftyData.EntranceMessage[language].replace('$$', agentName)
  },
  {
    me: false,
    author: agentName,
    body: lawftyData.WelcomeMessage[language].replace('$$', agentName),
    buttons: button_list
  }
];

const App = () => {
  const timerRef = useRef<IdleTimer>();
  const [inputVisible, setInputVisible] = useState(true);
  const [messages, setMessages] = useState<any[]>();
  const [secondSetCaseTypes, setSecondSetCaseTypes] = useState<any[]>([]);
  const [placeholderText, setPlaceholderText] = useState('');
  const [variant, setVariant] = useState('');
  const [postId, setPostId] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [uuid, setUuid] = useState('');

  const onIdle = (e: any, messages: any[]) => {
    if (conversationId && conversationId !== '') {
      const dbRef = getRef(
        'chat/conversations/' + conversationId + '/ChatOver'
      );
      onValue(dbRef, (snapshot) => {
        const chatOver = snapshot.val();
        if (chatOver !== 'true') {
          let newButtons: any;
          let newOptions: any;
          let newInput: any;
          let timeOut: number = 0;
          let secondSetQuestionId: any;
          const newMessage = lawftyData['ChatTimeout'][language];
          sendMessage(
            messages,
            newMessage,
            newButtons,
            newOptions,
            newInput,
            timeOut,
            secondSetQuestionId
          );
          setInputVisible(false);
          closeChat(messages, conversationId, postId);
        }
      });
    }
  };

  const checkForExperiment = () => {
    const dbRef = getRef('experiments');
    const activeRef = query(dbRef, equalTo('true'));
    onValue(
      activeRef,
      (snapshot) => {
        let experiment = snapshot.val();
        if (experiment && experiment[5]) {
          experiment = experiment[5];
          const variantRef = getRef('chat/users/' + uuid + '/Variant');
          onValue(
            variantRef,
            (snapshot) => {
              let variant = snapshot.val();
              if (!variant) {
                const rand = Math.floor(Math.random() * 100 + 1);
                if (rand <= 50) {
                  variant = 'Champion';
                } else {
                  variant = 'Challenger';
                }
                dbHelper('chat/users/' + uuid, { Variant: variant });
                setSecondSetCaseTypes(
                  lawftyData.LawyerCaseTypes[campaignType][language].sort()
                );
              } else {
                setSecondSetCaseTypes(
                  lawftyData.LawyerCaseTypes[campaignType][language].sort()
                );
              }
            },
            {
              onlyOnce: true
            }
          );
        } else {
          setVariant('Champion');
          setSecondSetCaseTypes(
            lawftyData.LawyerCaseTypes[campaignType][language].sort()
          );
        }
        setMessages(initialMessages);
        setInputVisible(false);
        setPlaceholderText('');
      },
      {
        onlyOnce: true
      }
    );
    storeMessages(initialMessages, false, messages || []);
  };

  const setUpData = (uuid: string) => {
    const cid = shortId.generate();
    const pid = shortId.generate();
    const time = getCurrentTimeInNY();
    if (cid && pid) {
      setConversationId(cid);
      setPostId(pid);

      const userRef = getRef('chat/users/' + uuid);
      update(userRef, { ConversationId: cid, PostId: pid });

      const conversationRef = getRef('chat/conversations/' + cid);
      update(conversationRef, { UserId: uuid, PostId: pid, UpdatedAt: time  });

      const postRef = getRef('chat/posts/' + pid);
      update(postRef, { UserId: uuid });
      initialParameters(pid, cid, initialMessages);
    }

    checkForExperiment();
  };

  const checkOrCreateUuid = () => {
    let uuid;
    const time = getCurrentTimeInNY();
    try {
      uuid = sessionStorage.getItem('uuid');
    } catch (err) {
      uuid = null;
    }
    // if its the first time chatbot has loaded
    if (!uuid || uuid === '') {
      uuid = shortId.generate();
      try {
        sessionStorage.setItem('uuid', uuid);
      } catch (err) {
        console.log(err);
      }
      setUpData(uuid);
    } else {
      setInputVisible(false);
      const dbRef = getRef('chat/users/' + uuid + '/ConversationId');
      onValue(
        dbRef,
        (snapshot) => {
          const cid = snapshot.val();
          if (cid && cid !== '') {
            setConversationId(cid);
            dbHelper('chat/conversations/' + cid, {
              ChatOver: 'false',
              UpdatedAt: time

            });
            const msgRef = getRef('chat/conversations/' + cid + '/Messages');
            onValue(
              msgRef,
              (snapshot) => {
                const storedMessages = snapshot.val();
                if (storedMessages && Object.keys(storedMessages).length !== 0) {
                  const lastMessage = storedMessages[storedMessages.length - 1];
                  if (
                    lastMessage.options === undefined &&
                    lastMessage.input === undefined &&
                    lastMessage.buttons === undefined
                  ) {
                    setInputVisible(true);
                  }
                  storedMessages.map((message: MessageProps, i: number) => {
                    if (i + 1 < storedMessages.length) message.disabled = true;
                    return message;
                  });
                  if (storedMessages !== messages) {
                    setMessages(storedMessages);
                  }
                  setPlaceholderText('');
                  storeMessages(storedMessages, true, messages || []);
                }
              },
              {
                onlyOnce: true
              }
            );
          }
        },
        {
          onlyOnce: true
        }
      );
      const postRef = getRef('chat/users/' + uuid + '/PostId');
      onValue(
        postRef,
        (snapshot) => {
          setPostId(snapshot.val());
        },
        {
          onlyOnce: true
        }
      );
    }
    return uuid;
  };

  const showInput = () => {
    setTimeout(() => {
      setInputVisible(true);
    }, 4200);
  };

  const storeMessages = (
    currentMes: any[] | undefined,
    disable: boolean,
    messages: any[]
  ) => {
    if (disable) {
      currentMes?.map((message: { disabled: boolean }, i: number) => {
        if (i + 1 < currentMes.length) {
          message.disabled = true;
        }
        return message;
      });
    }

    const time = getCurrentTimeInNY();
    if (currentMes) {
      if (conversationId === null || conversationId === undefined) {
        const dbRef = getRef('chat/users/' + uuid + '/ConversationId');
        onValue(
          dbRef,
          (snapshot) => {
            const cid = snapshot.val();
            if (cid && cid !== '') {
              setConversationId(cid);
              dbHelper('chat/conversations/' + cid, {
                Messages: currentMes,
                UpdatedAt: time
              });
            }
          },
          {
            onlyOnce: true
          }
        );
      } else if (conversationId !== '') {
        dbHelper('chat/conversations/' + conversationId, {
          Messages: currentMes,
          UpdatedAt: time
        });
      }

      if (variant && variant !== '') {
        dbHelper('visits/' + variant + '/' + uuid, { Messages: currentMes });
      }
      if (currentMes !== messages) {
        setMessages(currentMes);
      }
    }
  };

  const handleNewMessage = (text: string, messages: any[]) => {
    if (messages) {
      storeMessages(
        [...messages, { me: true, author: 'Me', body: text }],
        true,
        messages
      );
    }
  };

  const sendMessage = (
    messages: any[],
    newMessage: any,
    newButtons: any,
    newOptions: any,
    newInput: any,
    timeOut: number | undefined,
    secondSetQuestionId: string
  ) => {
    setTimeout(() => {
      setPlaceholderText('');
      setInputVisible(false);
      if (messages && messages.length > 0) {
        storeMessages(
          [
            ...messages,
            {
              me: false,
              author: agentName,
              body: newMessage,
              buttons: newButtons || null,
              options: newOptions || null,
              input: newInput || null,
              secondSetQuestionId: secondSetQuestionId || null
            }
          ],
          false,
          messages
        );
      }
    }, timeOut);
  };

  const getNextMessage = (text: string, messages: any[]) => {
    let newMessage: any;
    let newButtons: any;
    let newOptions: any;
    let newInput: string;
    let timeOut = 1000;
    let secondSetQuestionId: string;
    const time = getCurrentTimeInNY();
    if (conversationId && conversationId !== '') {
      const dbRef = getRef(
        'chat/conversations/' + conversationId + '/QuestionsLeft'
      );
      onValue(
        dbRef,
        (snapshot) => {
          const questionsLeft = snapshot.val();
          const inputRef = getRef(
            'chat/conversations/' + conversationId + '/InputFieldsLeft'
          );
          onValue(
            inputRef,
            (snapshot) => {
              const inputFieldsLeft = snapshot.val();
              const currentRef = getRef(
                'chat/conversations/' + conversationId + '/CurrentQuestionSet'
              );
              onValue(
                currentRef,
                (snapshot) => {
                  const currentQuestionSet = snapshot.val();
                  const caseRef = getRef(
                    'chat/conversations/' + conversationId + '/LawftyCase'
                  );
                  onValue(
                    caseRef,
                    (snapshot) => {
                      const lawftyCase = snapshot.val();
                      if (questionsLeft && questionsLeft.length > 0) {
                        //if we have lawfty or second set questions in questionsLeft -- display the first one, then deconste from the session store
                        newMessage = questionsLeft[0].prompt;
                        if (
                          questionsLeft[0].options &&
                          questionsLeft[0].options.length < 10
                        ) {
                          newButtons = questionsLeft[0].options;
                        } else {
                          newOptions = questionsLeft[0].options;
                        }
                        timeOut = 4000;
                        secondSetQuestionId = questionsLeft[0].questionId;
                        if (questionsLeft[0].options === undefined) {
                          timeOut = 4000;
                        }

                        if (questionsLeft[0].id === 1 && text.length > 250) {
                          questionsLeft.shift();
                          newMessage = questionsLeft[0].prompt;
                          newButtons = questionsLeft[0].options;
                        }
                        if (questionsLeft[0].id === 1.5) {
                          newInput = 'PhoneNumber';
                        }
                        questionsLeft.shift();
                        dbHelper('chat/conversations/' + conversationId, {
                          QuestionsLeft: questionsLeft,
                          UpdatedAt: time
                        });
                      } else if (text === 'NO') {
                        // if they don't select any case types
                        const url =
                          window.location !== window.parent.location
                            ? document.referrer
                            : document.location.href;
                        dbHelper('chat/posts/' + postId, { CurrentPage: url });
                        const otherSeq = lawftyData.OtherSequence[language];
                        newMessage = otherSeq[0].prompt;
                        timeOut = 4000;
                        otherSeq.shift();
                        dbHelper('chat/conversations/' + conversationId, {
                          QuestionsLeft: otherSeq,
                          UpdatedAt: time
                        });
                      } else if (
                        secondSetCaseTypes
                          .map((m: { answer_text: any }) => m.answer_text)
                          .includes(text)
                      ) {
                        //if they clicked on a second set case type, get the questions that apply
                        dbHelper('chat/conversations/' + conversationId, {
                          LawftyCase: 'false',
                          UpdatedAt: time
                        });
                        const secondSetData =
                          text === 'BANCARROTA' || text === 'BANKRUPTCY'
                            ? secondSetNolo
                            : secondSetQuestions;
                        const url =
                          window.location !== window.parent.location
                            ? document.referrer
                            : document.location.href;
                        dbHelper('chat/posts/' + postId, {
                          // @ts-ignore
                          CaseType: lawftyData['CaseTypeMap'][text],
                          PracticeAreaId:
                            // @ts-ignore
                            secondSetData.practiceAreas[language][text]
                              .practice_area,
                          CurrentPage: url
                        });
                        const questionsLeft =
                          // @ts-ignore
                          secondSetData.practiceAreas[language][text].questions;
                        // @ts-ignore
                        newMessage = lawftyData[language][text].prompt;
                        newInput = 'PhoneNumber';
                        timeOut = 4000;
                        dbHelper('chat/conversations/' + conversationId, {
                          QuestionsLeft: questionsLeft,
                          UpdatedAt: time
                        });
                      } else if (text === 'OTHER' || text === 'OTRO') {
                        //if they hit other (in the first question) then get the second set case types
                        newMessage = lawftyData.MoreOptionsPrompt[language];
                        const caseTypes = secondSetCaseTypes.sort(function (a, b) {
                          const textA = a.answer_text.toUpperCase();
                          const textB = b.answer_text.toUpperCase();
                          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        });
                        caseTypes.push({
                          answer: 1,
                          answer_text: 'NO'
                        });
                        setSecondSetCaseTypes(caseTypes.sort());
                        newButtons = caseTypes.sort();
                      } else if (
                        button_list
                          .map((m: { answer_text: any }) => m.answer_text)
                          .includes(text)
                      ) {
                        //if they hit a lawfty case type get the Lawfty question sequence
                        const url =
                          window.location !== window.parent.location
                            ? document.referrer
                            : document.location.href;
                        dbHelper('chat/posts/' + postId, {
                          // @ts-ignore
                          CaseType: lawftyData['CaseTypeMap'][text],
                          CurrentPage: url
                        });
                        // @ts-ignore
                        newMessage = lawftyData[language][text][0].prompt;
                        newInput = 'PhoneNumber';
                        timeOut = 4000;
                        dbHelper('chat/conversations/' + conversationId, {
                          // @ts-ignore
                          QuestionsLeft: [lawftyData[language][text][1]].concat(
                            lawftyData.LawftySequence[language]
                          ),
                          LawftyCase: 'true',
                          UpdatedAt: time
                        });
                      } else if (currentQuestionSet === 'initial-questions') {
                        //if none of above -- then were at the info grab part of the convo.
                        setInputVisible(false);
                        dbHelper('chat/conversations/' + conversationId, {
                          CurrentQuestionSet: 'input-fields',
                          UpdatedAt: time
                        });
                        const input = inputFieldsSequence[0];
                        newInput = input;
                        // @ts-ignore
                        newMessage = lawftyData[input][language];
                        timeOut = 4000;
                        inputFieldsSequence.shift();
                        dbHelper('chat/conversations/' + conversationId, {
                          InputFieldsLeft: inputFieldsSequence,
                          UpdatedAt: time
                        });
                      } else if (
                        inputFieldsLeft &&
                        inputFieldsLeft.length > 0
                      ) {
                        //continue info grab
                        const input = inputFieldsLeft[0];
                        newInput = input;
                        // @ts-ignore
                        newMessage = lawftyData[input][language];
                        timeOut = 4000;
                        inputFieldsLeft.shift();
                        dbHelper('chat/conversations/' + conversationId, {
                          InputFieldsLeft: inputFieldsLeft,
                          UpdatedAt: time
                        });
                      } else if (
                        lawftyCase === 'false' &&
                        currentQuestionSet === 'input-fields'
                      ) {
                        //ask last question for lawyer.com
                        newMessage = lawftyData.LastLawyerPrompt[language];
                        showInput();
                        timeOut = 4000;
                        dbHelper('chat/conversations/' + conversationId, {
                          CurrentQuestionSet: 'final-questions',
                          UpdatedAt: time
                        });
                      } else {
                        //end of the convo.
                        if (lawftyCase === 'false') {
                          dbHelper('chat/posts/' + postId, {
                            SendToNolo: 'true'
                          });
                        }
                        newMessage = lawftyData.ClosingMessage[language];
                        setInputVisible(false);
                        timeOut = 4000;
                        closeChat(messages, conversationId, postId);
                      }

                      sendMessage(
                        messages,
                        newMessage,
                        newButtons,
                        newOptions,
                        newInput,
                        timeOut,
                        secondSetQuestionId
                      );
                    },
                    {
                      onlyOnce: true
                    }
                  );
                },
                {
                  onlyOnce: true
                }
              );
            },
            {
              onlyOnce: true
            }
          );
        },
        {
          onlyOnce: true
        }
      );
    }
  };

  useEffect(() => {
    const uuid = checkOrCreateUuid();
    setUuid(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (conversationId) {
      const msgsRef = getRef(
        'chat/conversations/' + conversationId + '/Messages'
      );
      onValue(
        msgsRef,
        (snapshot) => {
          let pText = '';
          const storedMessages = snapshot.val();
          if (storedMessages && Object.keys(storedMessages).length !== 0) {
            const lastMessage = storedMessages[storedMessages.length - 1];
            if (
              lastMessage.options === undefined &&
              lastMessage.input === undefined &&
              lastMessage.buttons === undefined
            ) {
              setInputVisible(true);
              pText = '';
            }
            if (lastMessage.body === lawftyData.ClosingMessage[language]) {
              setInputVisible(false);
            }
            if (lastMessage.author === 'Me') {
              setInputVisible(false);
              pText = lawftyData.OperatorTyping[language].replace(
                '$$',
                agentName
              );
            }
            storedMessages.map((message: MessageProps, i: number) => {
              if (i + 1 < storedMessages.length) message.disabled = true;
              return message;
            });
            if (lastMessage.author === 'Me') {
              if (messages) {
                getNextMessage(lastMessage.body, messages);
              }
            }
            setPlaceholderText(pText);
          }
        },
        {
          onlyOnce: true
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, messages]);

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      if (conversationId) {
        const time = getCurrentTimeInNY();
        dbHelper('chat/conversations/' + conversationId, { ChatOver: 'true', UpdatedAt: time });
        dbHelper('chat/posts/' + postId, {
          Conversation: formatConversation(messages)
        });
      }
    });

    if (uuid && uuid !== '') {
      const dbRef = getRef('chat/users/' + uuid);
      onValue(
        dbRef,
        (snapshot: any) => {
          const userInfo = snapshot.val();
          if (userInfo) {
            const cid = userInfo['ConversationId'];
            if (cid && cid !== '') {
              setConversationId(cid);
              setVariant(userInfo['Variant']);
              setSecondSetCaseTypes(
                lawftyData.LawyerCaseTypes[campaignType][language].sort()
              );
            }
          }
        },
        {
          onlyOnce: true
        }
      );
    }
  }, [conversationId, messages, postId, uuid]);

  return (
    <div>
      <IdleTimer
        // @ts-ignore
        ref={timerRef}
        element={document}
        onIdle={(e) => onIdle(e, messages || [])}
        debounce={250}
        timeout={1000 * 60 * 15}
      />
      <div
        className={`${
          brand === 'd2c' ? 'bg-d2c' : 'bg-white'
        } flex flex-col box-border bottom-0 right-0 absolute z-50 rounded text-[13px] leading-6 text-[#333] h-full w-full`}
      >
        <FirmLogo campaignId={campaignId} brand={brand} />
        <SecurityNotification language={language} brand={brand} />
        <MessageList
          messages={messages || []}
          postId={postId}
          conversationId={conversationId}
          language={language}
          onClick={(text) => handleNewMessage(text, messages || [])}
          brand={brand}
        />
        {inputVisible ? (
          <MessageForm
            language={language}
            disabled={false}
            onMessageSend={(text) => handleNewMessage(text, messages || [])}
            brand={brand}
          />
        ) : (
          <Placeholder displayText={placeholderText} brand={brand} />
        )}
        <Disclaimer language={language} />
      </div>
    </div>
  );
};

export default App;
