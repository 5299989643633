import { dbHelper, getRef } from '../helpers/db_helper';
import { SelectProps } from '../@types';
import { onValue } from 'firebase/database';

const SelectButton = ({
  body,
  onClick,
  answerId,
  postId,
  secondSetQuestionId,
  styles
}: SelectProps) => {
  const handleClick = () => {
    if (secondSetQuestionId) {
      const dbRef = getRef('chat/posts/' + postId + '/NoloResponses');
      onValue(dbRef, (snapshot) => {
        const noloResponses = snapshot.val() || {};
        noloResponses[secondSetQuestionId - 1] = answerId;
        dbHelper('chat/posts/' + postId, { NoloResponses: noloResponses });
      });
    }
    onClick(body);
    // @ts-ignore
    document.getElementById('dropdown-basic-button')?.click((e) => {
      e.toElement.parentElement.click();
    });
  };
  return (
    <button
      className={`${styles} leading-4 m-1.25 py-1.25 px-2.5 bg-white text-black font-bold block border-none text-left w-full`}
      onClick={handleClick}
    >
      {body}
    </button>
  );
};

export default SelectButton;
