import Button from './Button';
import { ButtonGroupProps } from '../@types';
import { useState } from 'react';

const ButtonGroup = ({
  buttonList,
  onClick,
  disabled,
  postId,
  secondSetQuestionId,
  brand
}: ButtonGroupProps) => {
  const [isDisabled, setDisabled] = useState(disabled);
  const handleClick = (variant: string) => {
    onClick(variant);
    setDisabled(true);
  };

  return (
    <>
      {buttonList.map((btn, i) => {
        return (
          <Button
            key={btn.answer_text}
            postId={postId}
            answerId={btn.answer}
            secondSetQuestionId={secondSetQuestionId}
            body={btn.answer_text}
            onClick={handleClick}
            disabled={isDisabled}
            brand={brand}
          />
        );
      })}
    </>
  );
};

export default ButtonGroup;
