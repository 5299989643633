import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  endAt,
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  update
} from 'firebase/database';

let firebaseApp: FirebaseApp;
export const dbInitialize = () => {
  const firebaseConfig = {
    apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
    authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
    databaseURL: process.env['REACT_APP_FIREBASE_DATABASE_URL'],
    projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
    storageBucket: '',
    messagingSenderId: process.env['REACT_APP_FIREBASE_MESSAGING_SENDER_ID'],
    appId: process.env['REACT_APP_FIREBASE_APP_ID']
  };
  // Initialize Firebase
  firebaseApp = initializeApp(firebaseConfig);
};

export const dbHelper = (location: string, updates: Object) => {
  const db = getDatabase(firebaseApp);
  const dbRef = ref(db, location);
  update(dbRef, updates)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const dbHelperGet = (location: string) => {
  const db = getDatabase(firebaseApp);
  const dbRef = ref(db, location);
  onValue(dbRef, (snapshot) => {
    return snapshot.val();
  });
};

export const getRef = (location: string) => {
  const db = getDatabase(firebaseApp);
  const dbRef = ref(db, location);
  return dbRef;
};

export const deleteABtestChats = (location: string) => {
  const ref = getRef(location);
  remove(ref);
};

export const deleteOldChats = (location: string, order: string) => {
  const ref = getRef(location);
  const today = new Date();
  const dayInMilliseconds = 86400000;
  return query(ref, orderByChild(order), endAt(today.getTime() - dayInMilliseconds * 3));
};
