import { dbHelper, getRef } from '../helpers/db_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from '../assets/questions/lawfty_messages.json';
import { useEffect, useRef, useState } from 'react';
import { NameProps } from '../@types';
import { onValue } from 'firebase/database';

const Name = ({
  onClick,
  disabled,
  conversationId,
  postId,
  language
}: NameProps) => {
  const [isDisabled, setDisabled] = useState(disabled);
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const val = e.target?.value;
      onClick(val);
      setDisabled(true);
      if (val) {
        let index = val.indexOf(' ');
        if (index < 0) {
          index = val.length;
        }
        const first = val.substring(0, index);
        const last = val.substring(index + 1);
        dbHelper('chat/conversations/' + conversationId, { Name: val });
        dbHelper('chat/posts/' + postId, { FirstName: first, LastName: last });
      }
    }
  };

  const handleFormSubmit = () => {
    if (!isDisabled) {
      const val = name;
      onClick(val);
      setDisabled(true);
      if (val) {
        let index = val.indexOf(' ');
        if (index < 0) {
          index = val.length;
        }
        const first = val.substring(0, index);
        const last = val.substring(index + 1);
        dbHelper('chat/conversations/' + conversationId, { Name: val });
        dbHelper('chat/posts/' + postId, { FirstName: first, LastName: last });
      }
    }
  };

  const handleChange = (e: any) => {
    setName(e.target.value);
    setDisabled(false);
  };

  useEffect(() => {
    if (conversationId) {
      const dbRef = getRef('chat/conversations/' + conversationId + '/Name');
      onValue(dbRef, (snapshot) => {
        const name = snapshot.val();
        if (name) {
          setName(name);
        }
      });
    }
    inputRef.current?.focus();
  }, [conversationId]);

  return (
    <div>
      <span className="w-input inline-block">
        <input
          className={`${
            isDisabled
              ? 'border-b-2 border-b-solid border-b-darkgray'
              : 'border-none bg-valid'
          } py-1.25 px-0.75 w-full text-base focus:outline-none focus:bg-valid touch-none`}
          id="name-input"
          // @ts-ignore
          ref={inputRef}
          type="text"
          placeholder={lawftyData.NamePlaceholder[language]}
          value={name || ''}
          onChange={(e) => handleChange(e)}
          onKeyPress={handleKeyDown}
          disabled={isDisabled}
        />
      </span>
      <span className="w-1/10 inline-block float-right">
        <button
          type="submit"
          className="bg-send border-none text-white font-bold h-send w-send"
          onClick={handleFormSubmit}
        >
          <span className="arrow-container">
            <FontAwesomeIcon icon="arrow-right" />
          </span>
        </button>
      </span>
    </div>
  );
};

export default Name;
