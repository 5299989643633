import { dbHelper, getRef } from '../helpers/db_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from '../assets/questions/lawfty_messages.json';
import { EmailProps } from '../@types';
import { useEffect, useRef, useState } from 'react';
import { onValue } from 'firebase/database';

const Email = ({ onClick, language, postId, disabled }: EmailProps) => {
  const [email, setEmail] = useState<string>('');
  const [isDisabled, setDisabled] = useState<boolean>(disabled || false);
  const [style, setStyle] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (postId) {
      const dbRef = getRef('chat/posts/' + postId + '/Email');
      onValue(dbRef, (snapshot) => {
        const val = snapshot.val();
        if (val !== null && val !== undefined) {
          setEmail(val);
        }
      });
    }
    inputRef.current?.focus();
  }, [postId]);

  useEffect(() => {
    if (isDisabled) {
      setStyle('email-no-entry');
    } else if (email && email.toString().match(/.+@.+\..+/g)) {
      setStyle('email-valid');
    } else {
      setStyle('email-invalid');
    }
  }, [isDisabled, email]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const val = e.target?.value;
      onClick(val);
      setDisabled(true);
      dbHelper('chat/posts/' + postId, { Email: val });
    }
  };

  const handleFormSubmit = () => {
    if (!isDisabled) {
      onClick(email);
      setDisabled(true);
      dbHelper('chat/posts/' + postId, { Email: email });
    }
  };

  return (
    <div>
      <span className="w-input inline-block">
        <input
          className={`${style} border-0 py-1.25 px-0.75 focus:bg-email-input w-full`}
          id="email-input"
          ref={inputRef}
          type="text"
          placeholder={lawftyData.EmailPlaceholder[language]}
          value={email || ''}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyDown}
          disabled={isDisabled}
        />
      </span>
      <span className="w-1/10 inline-block float-right">
        <button
          className="bg-send border-0 text-white font-bold h-send w-send"
          id="email-send-button"
          type="submit"
          onClick={handleFormSubmit}
        >
          <span className="arrow-container">
            <FontAwesomeIcon icon="arrow-right" />
          </span>
        </button>
      </span>
    </div>
  );
};

export default Email;
