import { dbHelper, getRef } from '../helpers/db_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from '../assets/questions/lawfty_messages.json';
import { ZipCodeProps } from '../@types';
import { useEffect, useRef, useState } from 'react';
import { onValue } from 'firebase/database';

const ZipCode = ({ onClick, postId, language, disabled }: ZipCodeProps) => {
  const [zipCode, setZipCode] = useState('');
  const [isDisabled, setDisabled] = useState(disabled || false);
  const [style, setStyle] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (postId) {
      const dbRef = getRef('chat/posts/' + postId + '/Address');
      onValue(dbRef, (snapshot) => {
        const zip = snapshot.val();
        if (zip) {
          setZipCode(zip);
        }
      });
    }
    inputRef.current?.focus();
  }, [postId]);

  useEffect(() => {
    if (!zipCode || isDisabled) {
      setStyle('zip-code-no-entry');
    } else if (zipCode.length === 5) {
      setStyle('zip-code-valid');
    } else {
      setStyle('zip-code-invalid');
    }
  }, [isDisabled, zipCode]);

  const handleChange = (e: any) => {
    setZipCode(e.target.value);
    setDisabled(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const val = e.target.value;
      onClick(val);
      setDisabled(true);
      dbHelper('chat/posts/' + postId, { Address: val });
    }
  };

  const handleFormSubmit = () => {
    if (!isDisabled) {
      onClick(zipCode);
      setDisabled(true);
      dbHelper('chat/posts/' + postId, { Address: zipCode });
    }
  };

  return (
    <div>
      <span className="w-input inline-block">
        <input
          className={`w-full pt-4 pr-4 pb-4 pl-1 border-none text-base focus:outline-none focus:bg-email-input touch-none ${style}`}
          id="zip-code-input"
          // @ts-ignore
          ref={inputRef}
          type="text"
          placeholder={lawftyData.ZipCodePlaceholder[language]}
          value={zipCode || ''}
          onChange={(e) => handleChange(e)}
          onKeyPress={handleKeyDown}
          disabled={isDisabled}
        />
      </span>
      <span className="w-1/10 inline-block float-right">
        <button
          className="bg-send border-none text-white font-bold h-send w-send"
          id="zip-send-button"
          type="submit"
          onClick={handleFormSubmit}
        >
          <span className="arrow-container">
            <FontAwesomeIcon icon="arrow-right" />
          </span>
        </button>
      </span>
    </div>
  );
};

export default ZipCode;
