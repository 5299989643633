import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lawftyData from '../assets/questions/lawfty_messages.json';
import { SecurityNotificationProps } from '../@types';

const SecurityNotification = ({
  language,
  brand
}: SecurityNotificationProps) => {
  return (
    <CSSTransition
      classNames="SecurityNotification"
      in={true}
      appear={true}
      enter={false}
      exit={false}
      timeout={1200}
    >
      <div
        className={`${
          brand === 'd2c' ? 'bg-enabled-d2c' : 'bg-enabled'
        } box-border outline-none w-full p-1.25`}
      >
        <span className="flex-grow m-[1px] text-white p-1.25 text-center w-full">
          <FontAwesomeIcon icon="lock" />
        </span>
        <span className="flex-grow-0 flex-shrink-0 basis-[90%] m-[1px] pl-[1%] text-white text-lg">
          {lawftyData.SecurityNotification[language]}
        </span>
      </div>
    </CSSTransition>
  );
};

export default SecurityNotification;
