import { useEffect, useRef } from 'react';
import { MessageListProps } from '../@types';
import Message from './Message';

const MessageList = ({
  messages,
  onClick,
  language,
  postId,
  conversationId,
  brand
}: MessageListProps) => {
  const listRef = useRef<HTMLDivElement>();
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.MessageList')?.scroll({
        top: listRef.current?.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 1000);
  }, []);

  useEffect(() => {
    listRef.current!.scrollTop = listRef.current!.scrollHeight;
  }, [messages]);

  return (
    <div
      className="MessageList overflow-y-auto flex-grow box-border p-2.5"
      // @ts-ignore
      ref={listRef}
    >
      {messages.map((msg, i) => {
        return (
          <Message
            key={i}
            {...msg}
            postId={postId}
            conversationId={conversationId}
            language={language}
            onClick={onClick}
            brand={brand}
          />
        );
      })}
    </div>
  );
};

export default MessageList;
