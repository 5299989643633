import { CSSTransition } from 'react-transition-group';
import { PlaceholderProps } from '../@types';

const Placeholder = ({ displayText, brand }: PlaceholderProps) => {
  return (
    <CSSTransition
      classNames="Placeholder"
      in={true}
      enter={true}
      exit={true}
      appear={true}
      timeout={{
        appear: 4000,
        enter: 4000,
        exit: 1200
      }}
    >
      <form
        className={`flex ${
          brand === 'd2c' ? 'bg-d2c' : 'bg-white'
        } border-none p-1.25`}
      >
        <div className="pt-8 pr-0 pb-0 pl-1 h-14">{displayText}</div>
      </form>
    </CSSTransition>
  );
};

export default Placeholder;
