import { onValue } from 'firebase/database';
import { dbHelper, getRef } from './db_helper';
import queryString from 'query-string';

const prod_URL =
  process.env['REACT_APP_API_URL'] ||
  'https://webhook.site/fca6e5cf-5c80-423c-9b18-d992be9e0640';
const searchQuery = queryString.parse(window.location.search);
const brand = searchQuery.brand ? searchQuery.brand[0] : '';
const visitToken = searchQuery.visit_token || 'none';

export const changeTimezone = (date: Date, ianatz: string) => {
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz
    })
  );
  const diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() + diff);
};

export const closeChat = (
  messages?: any[],
  conversationId?: string,
  postId?: string
) => {
  const time = getCurrentTimeInNY();
  if (conversationId && conversationId !== '') {
    dbHelper('chat/conversations/' + conversationId, { ChatOver: 'true', UpdatedAt: time });
    setTimeout(() => {
      if (messages) {
        sendApiPost(messages, conversationId, postId);
      }
    }, 1000);
  }
};

export const formatConversation = (messages?: any[]) => {
  if (messages) {
    return messages
      .map((message) => message.author + ': ' + message.body)
      .join('\n');
  } else {
    return '';
  }
};

export const sendApiPost = (
  messages: any[],
  conversationId?: string,
  postId?: string
) => {
  if (postId && postId !== '') {
    dbHelper('chat/posts/' + postId, {
      Conversation: formatConversation(messages)
    });

    const dbRef = getRef('chat/posts/' + postId);
    onValue(
      dbRef,
      (snapshot) => {
        const post = snapshot.val();
        if (
          post['Phone'] !== null &&
          post['Phone'] !== undefined &&
          post['Phone'] !== ''
        ) {
          if (post['FirstName'] === undefined || post['FirstName'] === null) {
            post['FirstName'] = 'Unknown';
          }
          if (prod_URL) {
            fetch(prod_URL, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              mode: 'cors',
              body: JSON.stringify(post)
            }).then((res) => {
              console.log({ res });
            });
            const time = getCurrentTimeInNY();
            dbHelper('chat/conversations/' + conversationId, {
              PostSent: 'true',
              UpdatedAt: time
            });
          }
        }
      },
      {
        onlyOnce: true
      }
    );
  }
  return true;
};

export const initialParameters = (
  postId: string,
  conversationId: string,
  initialMessages: any[]
) => {
  const current = new Date();
  const today = changeTimezone(current, 'America/New_York');
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  const url =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;
  const initialUpdates = {
    Date: date,
    Time: time,
    CurrentPage: url,
    Source: 'LAWFTY',
    VisitToken: visitToken,
    Brand: brand
  };
  const currentNYtime = getCurrentTimeInNY();
  if (postId && conversationId && postId !== '' && conversationId !== '') {
    dbHelper('chat/posts/' + postId, initialUpdates);
    dbHelper('chat/conversations/' + conversationId, {
      PostSent: 'false',
      CurrentQuestionSet: 'initial-questions',
      Messages: initialMessages,
      UpdatedAt: currentNYtime
    });
  }
};

export const getCurrentTimeInNY = () => {
  const current = new Date();
  const today = changeTimezone(current, 'America/New_York');
  return today.getTime();
}
