import React from 'react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { dbInitialize } from './helpers/db_helper';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Always redirect production to https with url params
if (
  window.location.href.match(/lawfty/) &&
  window.location.protocol !== 'https:'
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
} else {
  dbInitialize();
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
