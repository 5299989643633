import { dbHelper, getRef } from '../helpers/db_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import InvalidAlert from './InvalidAlert';
import { PhoneProps } from '../@types';
import { useEffect, useRef, useState } from 'react';
import lawftyData from '../assets/questions/lawfty_messages.json';
import { E164Number } from 'libphonenumber-js/types';
import { onValue } from 'firebase/database';

const PhoneNumber = ({ onClick, postId, language, disabled }: PhoneProps) => {
  const [value, setValue] = useState<E164Number | undefined>();
  const [isDisabled, setDisabled] = useState(disabled);
  const [invalidMsg, setInvalidMsg] = useState('');
  const [style, setStyle] = useState('');
  const inputRef = useRef<any>();

  useEffect(() => {
    if (postId) {
      const dbRef = getRef('chat/posts/' + postId + '/Phone');
      onValue(
        dbRef,
        (snapshot) => {
          const phone = snapshot.val();
          if (phone) {
            setValue(phone);
          }
        },
        {
          onlyOnce: true
        }
      );
    }
    inputRef.current?.focus();
  }, [postId]);

  useEffect(() => {
    if (!value || isDisabled) {
      setStyle('phone-number-no-entry');
    } else if (value.length === 12) {
      setStyle('phone-number-valid');
    } else {
      setStyle('phone-number-invalid');
    }
  }, [isDisabled, value]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && style === 'phone-number-valid') {
      const val = e.target.value;
      onClick(val);
      setDisabled(true);
      setInvalidMsg('');
      dbHelper('chat/posts/' + postId, { Phone: val });
    } else if (e.key === 'Enter' && style === 'phone-number-invalid') {
      setInvalidMsg(lawftyData.PhoneNumberInvalidAlert[language]);
    }
  };

  const handleFormSubmit = () => {
    if (!isDisabled) {
      if (style === 'phone-number-valid') {
        onClick(value);
        setDisabled(true);
        setInvalidMsg('');
        dbHelper('chat/posts/' + postId, { Phone: value });
      } else if (style === 'phone-number-invalid') {
        setInvalidMsg(lawftyData.PhoneNumberInvalidAlert[language]);
      }
    }
  };

  return (
    <div>
      <InvalidAlert msg={invalidMsg} />
      <span className="w-input inline-block">
        <PhoneInput
          className={`w-full pl-1 border-none focus:border-none focus:outline-none focus:bg-email-input touch-none ${style}`}
          id="phone-number-input"
          ref={inputRef}
          placeholder={lawftyData.PhoneNumberPlaceholder[language]}
          country="US"
          defaultCountry="US"
          value={value || ''}
          onChange={(value) => setValue(value)}
          onKeyPress={handleKeyDown}
          disabled={isDisabled}
        />
      </span>
      <span className="w-1/10 inline-block float-right mr-[2%] ">
        <button
          className={`bg-send border-none text-white font-bold h-send w-send ${style}`}
          id="phone-send-button"
          type="submit"
          onClick={handleFormSubmit}
        >
          <span className="arrow-container">
            <FontAwesomeIcon icon="arrow-right" />
          </span>
        </button>
      </span>
    </div>
  );
};

export default PhoneNumber;
